import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-watcher-in-the-woods-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1980 Disney horror movie, The Watcher in the Woods"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 24</h1>
                    <h2>Halloween (Bonus)</h2>
                    <h3>November 6, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> John Hough // <b>Starring:</b> Lynn-Holly Johnson &amp; Bette Davis</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/24-The-Watcher-in-the-Woods-wguest-Teri-Gamble-Bonus-e2bi373/a-aairc8r" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 24, The Watcher in the Woods"></iframe>
                        </div>
                        <p><Link to="/transcripts/the-watcher-in-the-woods">Read the episode transcript</Link></p>
                        <p>Bryan and Dave are joined by Teri Gamble of the Horror Movie Survival Guide podcast to talk about Disney's bizarro 80's period where they experimented with more mature films resulting in one of their most easily-forgotten titles, The Watcher in the Woods. At one time, Disney producers and executives were convinced that this was going to be their finest hour, a horror movie to rival The Exorcist. Nowadays, if it's remembered at all, it's remembered for not making a whole lot of sense and having been pulled from theaters for extensive reshoots and two endings that had to be thrown out.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=3DFacqQp8uw" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=3DFacqQp8uw</OutboundLink></p>
                        <p>Watcher in the Woods original ending A: <OutboundLink href="https://www.youtube.com/watch?v=Bkpf2IzmMVg" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=Bkpf2IzmMVg</OutboundLink></p>
                        <p>Watcher in the Woods original ending B: <OutboundLink href="https://www.youtube.com/watch?v=nj7BTISeq7E" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=nj7BTISeq7E</OutboundLink></p>
                        <p>Horror Movie Survival Guide: <OutboundLink href="https://horrormoviesurvivalguidepodcast.com/" target="_blank" rel="noreferrer">https://horrormoviesurvivalguidepodcast.com/</OutboundLink></p>
                        <p>Teri Gamble on Instagram: <OutboundLink href="https://www.instagram.com/theterigamble" target="_blank" rel="noreferrer">https://www.instagram.com/theterigamble</OutboundLink></p>
                        <p>Teri Gamble on X: <OutboundLink href="https://twitter.com/TheTeriGamble" target="_blank" rel="noreferrer">https://twitter.com/TheTeriGamble</OutboundLink></p>
                        <p>Teri Gamble on Facebook: <OutboundLink href="https://www.facebook.com/theterigamble/" target="_blank" rel="noreferrer">https://www.facebook.com/theterigamble/</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/elves">Next Episode</Link></li>
                        <li><Link to="/episodes/halloween-1978">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)